
.page {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-bottom: 20px;
}

.loginHeader {
    display: flex;
    margin: 30px;
    border-bottom: grey 1px solid;
}

.loginLogo {
    position: absolute;
    top: 5px;
    width: 60px;
    height: 50px;
    background-image: url("../../../public/images/ivinco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto 0 auto 10px;
}

.loginTitle {
    display: flex;
    text-align: center;
    font-size:1rem;
    margin: auto;
}

.loginInput {
    padding: 5px 10px;
    border:  1px solid #3F3D56;
    border-radius: 10px;
    width: 45%;
    margin: 30px auto 0;
    font-size: 1rem;
}

.loginInput:focus {
    outline: none;
    border: 1px solid #0A9393;
}

.inputInvalid {
    border: 2px solid #A25C66;
}

.loginButton {
    width: 50%;
    margin: 30px auto 0;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #0A9393;
    color: white;
    font-size: 1rem;
    border: none;

}

.loginButtonEnabled {
    cursor: pointer;
    transition: transform 0.2s;
}

.loginButtonEnabled:hover {
    transform: scale(1.1);
}

.loginButtonDisabled {
    background-color: lightgrey;
}

