.escalationPage {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

.escalationTable {
    flex-grow: 1;
    display: grid;
    position: relative;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: max-content;
    margin: 30px auto;
    width: 95%;
    height: 95%;
    overflow: auto;
}

.tableCell {
   border: 1px solid black;
    text-align: center;
    padding: 5px;
    font-size: 1rem;

    word-break: break-word;
}

.itemLink {
    text-decoration: none;
    color: black;
}

.tableCellSelected {
    background-color: #bab8b8;
}

.historyItem {
    text-align: start;
    margin: 5px 0;
}

.historyExpandBlock {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    cursor: pointer;
}

.historyItemSpan {
    color: gray;
}

.historyItemArrow{
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.inputTableCell {
    display: flex;
    flex-direction: column;
}

.inputTableCell:hover {
    background-color: lightgray;
}


.inputTextArea {
    width: 95%;
    border: none;
    background-color: transparent;
    resize: none;
    overflow: auto;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.inputTextArea:hover {
    cursor: pointer;
    background-color: lightgray;
}

.inputTextAreaActive {
    border: 1px solid black;
    padding: 5px;
}

.inputCellButtonBlock {
    display: none;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.inputCellButtonBlockActive {
    display: flex;
}

.inputCellButton {
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px 10px;
    width: 30%;
    cursor: pointer;

}

.inputOkButton {
    background-color: #656B7A;
    color: white;
}

.inputCancelButton {
    border: 1px solid #656B7A;
    color: #656B7A;
}

.paginationBock {
    align-items: center;
    display: flex;
    position: relative;
    width: 95%;
    margin: 10px auto 30px;
}

.paginationSizeBlock {
    display: flex;
    width: 90px;
    flex-direction: column;
    position: relative;
}


.paginationSize {
    display: flex;
    border: 2px solid black;
    padding: 3px 0 3px 20px;
    border-radius: 5px;
}

.paginationSize::after{
    content: "";
    width: 30px;
    height: 15px;
    margin: 3px 3px auto 10px;
    display: inline-block;
    background-image: url("../../../public/images/select-arrows-active-up-svgrepo-com.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.paginationDropDown {
    display: none;
    position: absolute;
    bottom: 30px;
    list-style: none;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
    margin: 0;
    background-color: #656B7A;
    border-radius: 5px;
}

.paginationDropDownItem {
    width: 100%;
    text-align: center;
    color: white;
    margin: 5px auto;
}

.paginationDropDownItem:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

.paginationSizeBlock:hover  .paginationDropDown {
    display: flex;
}

.paginationSizeText {
    margin: 0 20px;
}

.totalRecordsText {
    margin: 0 auto;
}

.paginationPagesBlock {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    margin: 0 0 0 10px;
    padding: 0;
}

.paginationPageButtonCover {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    list-style: none;
    aspect-ratio: 1 / 1;
    border: 1px solid #656B7A;
    border-radius: 5px;
}

.paginationPageButtonCoverActive {
    background-color: #656B7A;
}

.paginationPageButton {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.paginationPageButtonActive {
    color: white;
}

.paginationPageButtonFirst {
    background-color: #656B7A;
    background-image: url("../../../public/images/white-arrow-left.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.paginationPageButtonLast {
    background-color: #656B7A;
    background-image: url("../../../public/images/white-arrow-right.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.loadingOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(224, 232, 247, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
