.modalOverlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loadOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(224, 232, 247, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalWindow {
    display: flex;
    position: relative;
    margin: 50px auto;
    flex-direction: column;
    background-color: white;
    width: 500px;
    border-radius: 7px;
}

.closeButton {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    cursor: pointer;
    background-image: url("../../../public/images/close-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    z-index: 1;
    transition: transform 0.3s;
}

.modalForm {
    display: flex;
    flex-direction: column;
    margin: 50px 45px 20px 10px;
}

.inputField {
    border: 2px solid #8C8C8CFF;
    font-size: 1rem;
    border-radius: 5px;
    padding: 5px;
    margin: 0 0 10px;
}

.editButton {
    margin: 10px auto;
    border: 1px solid #01a2d8;
    border-radius: 7px;
    background-color: #e1fafa;
    transition: transform 0.2s;
    font-size: 1rem;
    padding: 5px 10px;
}

.editButton:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.ediButtonDisabled {
    margin: 10px auto;
    background-color: #989898;
    color: black;
    border: 2px #989898 solid;
    border-radius: 7px;
    font-size: 1rem;
    padding: 5px 10px;
}