.buttonHint::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 10%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: rgba(246, 243, 243, 0.78);
    color: #000000;
    font-size: 0.6rem;
    text-shadow: none;
    white-space: nowrap;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 1s ease 1.5s;
}

.buttonHint:hover::after {
    visibility: visible;
    opacity: 1;
}


.rotatedIcon {
    opacity: 0.5;
    animation: rotation 0.5s infinite linear;
}




@keyframes rotation {
    0% {
        rotate: 0;
    }
    100% {
        rotate: 180deg;
    }
}