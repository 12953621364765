.overlay {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #96969680;
}

.modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 52px auto auto auto;
    max-width: 70%;
    max-height: 90vh;
    border: 1px solid #C8D8C5;
    background-color: white;
    border-radius: 25px;
}

.closeButton {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    cursor: pointer;
    background-image: url("../../../public/images/close-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    z-index: 1;
    transition: transform 0.3s;
}

.closeButton:hover {
    transform: scale(1.2);
}