.page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    margin: auto;
    background-color: #F5F5F5;
}

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 324px;
    background-color: #e1fafa;
    margin: 0;
    padding: 0;
}

.mainWindow {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sideBar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30px;
    background-color: #656B7A;
    border-left: #656B7A solid 1px;
}

.menuArrow {
    width: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    padding: 0;
    transition: transform 0.2s;
    background-color: transparent;
    border: none;
}

.menuArrowClosed {
    background-image: url("../../../public/images/white-arrow-right.png");
}

.menuArrowOpened {
    background-image: url("../../../public/images/white-arrow-left.png");
}

.menuArrow:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.noLoginMainDisplay {
    display: flex;
    background-image: url("../../../public/images/Matrix.gif");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

@media screen and (max-width: 2150px){
    .header {
        max-height: 216px;
    }
    .sideBar{
        width: 20px;
    }
    .menuArrow {
        width: 20px;
    }
}

@media screen and (max-width: 1510px){
    .header {
        max-height: 144px;
    }
    .sideBar{
        width: 15px;
    }
    .menuArrow {
        width: 15px;
    }
}