.loadOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(224, 232, 247, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.page {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.userInfoHeader {
    display: flex;
    height: 15%;
    margin: 15px 30px;
    border-bottom: grey 1px solid;
}

.userInfoLogo {
    width: 40px;
    height: 40px;
    background-image: url("../../../public/images/ivinco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto 0 auto 10px;
}

.userInfoTitle {
    display: flex;
    text-align: center;
    padding-right: 60px;
    font-size: 1rem;
    margin: auto;
}

.userInfoBody {
    display: flex;
    width: 100%;
    margin: 0 15px;
}

.userEditButtonsBlock {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.userEditButton {
    width: 100%;
    padding: 3px 5px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
}

.userEditButton:hover {
    transform: scale(1.1);
}

.userEditButtonActive {
    background-color: #0A9393;
}

.hiddenElement {
    visibility: hidden;
}

.userInfoAvatar {
    width: 60px;
    height: 60px;
    margin: 10px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    border: #737373 1px solid;
    border-radius: 50%;
}

.userInfoInfoBlock {
    margin: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 35%;
    width: 50%;
}

.userEditForm {
    font-size: 1rem;
    margin: 0;
    padding: 1px;
}

.userInfoItem {
    font-size: 1rem;
    margin: 0;
    padding: 1px;
}

.resetPassBlock {
    display: flex;
}

.resetPassForm {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
}

.resetPassField {
    font-size: 1rem;
    margin: 0;
    padding: 1px;
    text-align: center;
}

.resetPassField::placeholder {
    font-size: 1rem;
}

.logoutButton {
    width: 45%;
    margin: 30px auto 20px;
    padding: 14px 20px;
    border-radius: 10px;
    background-color: #0A9393;
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
}

.logoutButton:hover {
    transform: scale(1.1);
}