.alertBody {
    width: 99%;
    height: 90%;
    display: flex;
    position: relative;
    align-content: space-between;
    margin: auto;
    border: #8a8a8a solid 2px;
    border-radius: 10px;

}

.alertBody_small {
    width: 99%;
    height: 99%;
    display: flex;
    position: relative;
    align-content: space-between;
    margin: auto;
    border: #3b3b3b solid 1px;
    border-radius: 10px;
}

.projectName {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    font-size: 1.5rem;
    margin: auto 10px;
    border: 2px solid black;
    border-radius: 10%;
    padding: 10px;
}

.projectName_small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    font-size: 0.7rem;
    margin: auto 6px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 10%;
}

.selectCheckBox {
    margin-right: 5px;
}

.selectCheckBox_scaled {
    transform: scale(1.5);
}

.host {
    display: flex;
    height: 100%;
    width: 10%;
    align-items: center;
    margin: auto 5px;
    padding: 0 10px;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    border-left: slategrey solid 3px;
    border-right: slategrey solid 3px;
    overflow: hidden;
}

.host_small {
    display: flex;
    height: 100%;
    width: 10%;
    align-items: center;
    margin: auto 3px;
    padding: 0 4px;
    justify-content: center;
    font-size: 0.7rem;
    border-left: slategrey solid 1px;
    border-right: slategrey solid 1px;
    overflow: hidden;
}

.severity {
    display: flex;
    height: 100%;
    width: 5%;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    border-right: slategrey solid 3px;
    overflow: hidden;
}

.severity_small {
    display: flex;
    height: 100%;
    width: 5%;
    align-items: center;
    margin: auto 3px;
    padding: 0 4px;
    justify-content: center;
    font-size: 0.7rem;
    border-right: slategrey solid 1px;
    overflow: hidden;
}

.responsibleUser {
    display: flex;
    width: 50px;
    height: 50px;
    margin: auto 5px;
    padding: 10px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.responsibleUser:hover {
    transform: scale(0.8);
}

.responsibleUser_small {
    display: flex;
    width: 20px;
    height: 20px;
    margin: 4px;
    padding: 2px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: transform 0.15s;
}

.responsibleUser_small:hover {
    transform: scale(1.2);
}

.alertName {
    height: 100%;
    display: flex;
    width: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 1rem;
    overflow: hidden;
    border-left: slategrey solid 3px;
    border-right: slategrey solid 3px;
}
.alertName:hover {
    cursor: pointer;
}

.alertNameLink {
    text-decoration: none;
    color: black;
}

.textFields {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto 0;
    padding: 5px 0;
}

.textFieldsShare {
    font-size: 0.8rem;
    padding: 0;
}

.textFieldsShare_small {
    font-size: 0.6rem;
    padding: 0;
}

.alertName_small {
    height: 100%;
    display: flex;
    width: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    font-size: 0.7rem;
    overflow: hidden;
    border-left: slategrey solid 1px;
    border-right: slategrey solid 1px;
}

.alertName_small:hover {
    cursor: pointer;
}

.alertTime {
    display: flex;
    height: 100%;
    width: 7%;
    margin: 0 15px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    line-height: 1.3;
}

.alertTime_small {
    display: flex;
    height: 100%;
    width: 7%;
    margin: 0 6px;
    font-size: 0.7rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.message {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 52%;
    align-items: baseline;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    border-left: slategrey solid 3px;
    border-right: slategrey solid 3px;
}

.message::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 66%;
    top: -10px;
    transform: translateX(-50%);
    max-width: 1000px;
    max-height: 50vh;
    padding: 10px;
    margin-top: 30px;
    background-color: #333333E5;
    color: #fff;
    border-radius: 5px;
    z-index: 10;
    white-space: pre-line;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease 1.5s;
}

.message:hover::after {
    visibility: visible;
    opacity: 1;
}

.messageShort {
    width: 32%;
}


.message_small {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 52%;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    border-left: slategrey solid 1px;
    border-right: slategrey solid 1px;
    font-size: 0.7rem;
}

.message_small::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 66%;
    top: -40px;
    transform: translateX(-50%);
    max-width: 600px;
    padding: 10px;
    margin-top: 50px;
    background-color:  #333333E5;
    color: #fff;
    border-radius: 5px;
    z-index: 10;
    white-space: pre-line;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease 1.5s;
}

.message_small:hover::after {
    visibility: visible;
    opacity: 1;
}

.comment {
    display: flex;
    width: 20%;
    height: 100%;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    font-size: 1rem;
    border-right: slategrey solid 3px;
}

.comment_small {
    font-size: 0.7rem;
}


.controlButton {
    width: 40px;
    height: 40px;
    margin: auto 15px;
    border: none;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.2s;
}

.controlButton:hover {
    transform: scale(1.2);
}

.controlButton_small {
    width: 23px;
    height: 23px;
    margin: auto 6px;
    border: none;
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.2s;
}

.controlButton_small:hover {
    transform: scale(1.2);
}

.silenceWindow {
    z-index: 10;
    display: flex;
    padding: 6px 30px;
    position: absolute;
    right: 10%;
    top: 15px;
    background-color: #708090EA;
    border-radius: 20px;
}

.silenceWindow_small {
    padding: 5px 14px;
    top: 1px;
}

.silenceWindowActive {
    display: flex;
}

.silenceWindowDisabled {
    display: none;
}

.silenceComment {
    overflow: auto;
    font-size: 1rem;
    text-align: center;
    margin-right: 30px;
    width: 250px;
    border-radius: 10px;
    outline: none;
}

.silenceComment::placeholder {
    font-size: 1rem;
}



.silenceComment_small {
    font-size: 0.7rem;
    margin-right: 14px;
}

.silenceComment_small::placeholder {
    font-size: 0.7rem;
}

.silenceDuration {
    font-size: 1rem;
    text-align: center;
    margin-right: 30px;
    width: 150px;
    border-radius: 10px;
    outline: none;
}
.silenceDuration::placeholder {
    font-size: 1rem;
}

.silenceDuration_small {
    font-size: 0.7rem;
    margin-right: 14px;
    width: 70px;
}

.silenceDuration_small::placeholder {
    font-size: 0.7rem;
}


.silenceButton {
    font-size: 1.5rem;
    border-radius: 10px;
}

.silenceButton_small {
    font-size: 0.7rem;
}

.silenceButtonDisabled {
    background-color: #989898;
    color: black;
    border: 2px #989898 solid;
}
.silenceButtonEnabled {
    background-color: #e1fafa;
    border: 2px solid #C3FAFAFF;
    transition: transform 0.15s;
    color: black;
    cursor: pointer;
}

.silenceButtonEnabled:hover {
    cursor: pointer;
    transform: scale(0.9);
}

.fixInstructions {
    background-image: url("../../../public/images/info-icon.svg");
}

.silence {
    background-image: url("../../../public/images/mute-notification-svgrepo-com.svg");
}

.info {
    background-image: url("../../../public/images/comment-dots.svg");
}

.refresh {
    background-image: url("../../../public/images/refresh-cw-alt-bw-svgrepo-com.svg");
}

.failedRefresh {
    background-image: url("../../../public/images/failed_recheck_icon.svg");
}


@media screen and (max-width: 2150px){
    .alertBody {
        margin: auto;
        border: #8a8a8a solid 2px;
    }

    .projectName {
        height: 21px;
        width: 21px;
        font-size: 1.4rem;
        margin: auto 6px;
        padding: 4px;
    }

    .controlButton:hover {
        transform: scale(1.05);
    }

    .host {
        margin: auto 3px;
        padding: 0 6px;
        font-size: 0.7rem;
        border-left: slategrey solid 2px;
        border-right: slategrey solid 2px;
    }

    .selectCheckBox_scaled {
        transform: scale(1.2);
    }

    .severity {
        margin: auto;
        padding: 0;
        font-size: 0.7rem;
        border-right: slategrey solid 2px;
    }

    .responsibleUser {
        margin: auto 5px;
        width: 35px;
        height: 35px;
        padding: 6px;
    }

    .alertName {
        margin: auto;
        padding: 0 6px;
        font-size: 0.7rem;
        border-left: slategrey solid 2px;
        border-right: slategrey solid 2px;
    }

    .alertTime {
        margin: 0 10px;
        font-size: 0.7rem;
        line-height: 1.4;
    }

    .message {
        padding: 0 6px;
        border-left: slategrey solid 2px;
        border-right: slategrey solid 2px;
        font-size: 0.7rem;
    }

    .controlButton {
        width: 35px;
        height: 35px;
        margin: auto 10px;
    }

    .silenceWindow {
        padding: 8px 20px;
        right: 200px;
        top: 5px;
    }

    .silenceWindow_small {
        padding: 1px 14px;
        top: 2px;
    }

    .silenceComment {
        font-size: 0.9rem;
        margin-right: 20px;
    }

    .silenceDuration {
        font-size: 0.9rem;
        margin-right: 20px;
        width: 140px;
    }

    .silenceButton {
        font-size: 1rem;
    }
    .silenceButton_small {
        font-size: 0.7rem;
    }

}

@media screen and (max-width: 1510px){
    .alertBody {
        margin: auto;
    }

    .projectName {
        height: 18px;
        width: 18px;
        font-size: 1rem;
        margin: auto 4px;
        padding: 4px;
    }

    .host {
        margin: auto 2px;
        padding: 0 4px;
        font-size: 0.5rem;
        border-left: slategrey solid 1px;
        border-right: slategrey solid 1px;
    }

    .responsibleUser {
        width: 27px;
        height: 27px;
        margin: 6px;
        padding: 4px;
    }

    .alertName {
        margin: auto 6px;
        padding: 0 4px;
        font-size: 0.7rem;
        border-left: slategrey solid 1px;
        border-right: slategrey solid 1px;
    }

    .alertTime {
        margin: 0 6px;
        font-size: 0.6rem;
        line-height: 1;
    }

    .message {
        padding: 0 4px;
        border-left: slategrey solid 1px;
        border-right: slategrey solid 1px;
        font-size: 0.6rem;
    }

    .controlButton {
        width: 26px;
        height: 26px;
        margin: auto 6px;
    }

    .silenceWindow {
        padding: 1px 14px;
        right: 120px;
    }

    .silenceComment {
        font-size: 0.6rem;
        margin-right: 14px;
    }

    .silenceDuration {
        font-size: 0.6rem;
        margin-right: 14px;
        width: 90px;
    }

    .silenceButton {
        font-size: 0.9rem;
    }
}
