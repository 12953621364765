.page {
    display: block;
    width: 100%;
    overflow-y: auto;
}

.table {
    width: 95%;
    margin: 20px auto 30px;
    border-collapse: collapse;
    border: 2px solid #8C8C8CFF;
    font-size: 1rem;
}

.header {
    background-color: #E4F0F5FF;
}

.headerElement {
    border: 1px solid #A0A0A0FF;
}

.column {
    width: 10%;
}

.authorColumn {
    width: 100px;
}

.authorIcon {
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #3f3e3e;
    border-radius: 50%;
    margin: 5px auto;
}

.alertColumn {
    width: 20%;
}

.actionsColumn {
    width: 200px
}

.cell {
    border: 1px solid #3f3e3e;
}

.flexCell {
    display: flex;
    justify-content: space-between;
}

.textCell {
    font-size: 0.9rem;
    margin: 0 auto 0 5px;
    padding: 0;
}

.button {
    margin: auto;
    border: 1px solid #01a2d8;
    border-radius: 7px;
    background-color: #e1fafa;
    transition: transform 0.2s;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.1);
}
