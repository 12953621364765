.header {
    display: flex;
    width: 100%;
    position: relative;

}
.logo {
    width: 160px;
    height: 75px;
    background-image: url("../../../public/images/ivinco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto 15px auto 45px;
}

.onDutyPortal {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    min-height: 50px;
    background-color: white;
    border: darkgrey 2px solid;
    border-radius: 10px;
    margin: auto 0 0 30px;
    padding: 0 10px;
    font-size: 1.5rem;
}

.onDutyPortalActive {
    border: #01a2d8 2px solid;
}

.searchForm {
    display: flex;
    height: 50px;
    margin: auto 0 0 20px;
    padding: 2px 4px 2px 2px;
    border: #01A2D8 2px solid;
    border-radius: 10px;
    background-color: white;
}

.searchField {
    height: 50px;
    width: 420px;
    margin: 0;
    border: none;
    font-size: 1.5rem;
    padding: 0 8px;

}

.searchField:focus {
    outline: none;
}
.searchField::placeholder {
    color: #bbb;
}

.searchButton {
    height: 50px;
    width: 50px;
    border: none;
    background-color: transparent;
    background-image: url("../../../public/images/search.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.2s;
}

.searchButton:hover {
    cursor: pointer;
    transform: scale(0.9);
}

input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.resetSearchButton_hidden {
    visibility: hidden;
}

.resetSearchButton {
    margin: auto;
    height: 30px;
    width: 30px;
    background-color: transparent;
    background-image: url("../../../public/images/close-icon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    transition: transform 0.2s;
}

.resetSearchButton:hover {
    cursor: pointer;
    transform: scale(1.2);
}


.alertsCount {
    display: flex;
    margin: auto auto 0 50px;
    justify-content: center;
    align-items: end;
    font-size: 2rem;
}

.alertCountItems {
    font-weight: bold;
    margin: 0 10px 0 0;
    padding: 5px 8px;
    color: #01a2d8;
}

.alertsNumber {
   text-shadow: -1px -1px 0 #939292,
                1px -1px 0 #939292,
                -1px  1px 0 #939292,
                1px  1px 0 #939292;
}

.emergencyAlerts {
    color: #f67d7a;
}

.criticalAlerts {
    color: #FFBBBA;
}

.warningAlerts {
    color: #FEFFC1;
}

.infoAlerts {
    color: #BED8FF;
}

.otherAlerts {
    color: #bab8b8;
}

.totalAlerts {
    margin: 0;
}

.alertsCountTitle {
    margin: 0 0 0 20px;
    padding-right: 0;
}


.headerButton {
    min-width: 135px;
    min-height: 50px;
    margin: auto;
    background-color: transparent;
    border: #01a2d8 2px solid;
    border-radius: 10px;
    font-size: 2rem;
    padding: 12px 15px;
    text-align: center;
}

.headerButtonEnabled {
    cursor: pointer;
    transition: transform 0.4s;
}

.headerButtonDisabled {
    border: darkgrey 2px solid;
    color: darkgrey;
}

.headerButtonEnabled:hover {
    transform: scale(1.2);
    border-color:  #01A2D8;
    color:  #01A2D8;
}

.funcButton {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    margin: auto 30px 0 0;
    border: none;
    filter: grayscale(100%);
}

.funcButtonEnabled {
    cursor: pointer;
    transition: transform 0.2s;
    filter: none;
}
.funcButtonEnabled:hover {
    transform: scale(1.2);
}

.groupAlerts {
    border: none;
    border-radius: 10px;
    background-image: url('../../../public/images/group-list-color-svgrepo-com.svg');
}

.silenceButton {
    border: none;
    background-image: url('../../../public/images/silence_view.svg');
}

.silenceRulesButton {
    border: none;
    background-image: url('../../../public/images/silence_add.svg');
}

.funcButton:focus{
    outline: none;
}

.inspectButton {
    border: none;
    border-radius: 7px;
    background-image: url("../../../public/images/resize-button.svg");
}

.groupAckButton {
    background-image: url("../../../public/images/checked_color.svg");
}

.groupAckButton_disabled {
    background-image: url("../../../public/images/checked.svg");
    transition: none !important;
    transform: none !important;
    cursor: auto;
}

.groupComment {
    background-image: url("../../../public/images/pencil_color.svg");
}

.groupComment_disabled {
    background-image: url("../../../public/images/pencil_gray.svg");
    transition: none !important;
    transform: none !important;
    cursor: auto;
}

.refreshButton {
    border: none;
    background-image: url("../../../public/images/refresh-cw-alt-color-svgrepo-com.svg");
}

.failedRecheckButton {
    border: none;
    background-image: url("../../../public/images/color_failed_recheck_icon.svg");
}

.filterButton {
    background-image: url("../../../public/images/filter-svgrepo-com.svg");
}

.avatar {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 10px 45px 0;
    border-radius: 50%;
    border: 3px solid #01a2d8;
    transition: transform 0.2s;
    position: relative;
}
.avatar:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.commentWindow {
    width: 400px;
    height: 300px;
    position: absolute;
    background-color: #fff;
    z-index: 10;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.closeCommentWindowButton {
    height: 25px;
    width: 25px;
    margin: 15px 15px 0 auto;
    border: none;
    background-image: url("../../../public/images/close-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    transition: transform 0.3s;
}

.closeCommentWindowButton:hover {
    transform: scale(1.3);
    cursor: pointer;
}

.commentWindowTitle {
    margin: 5px auto 5px;
    text-align: center;
    font-weight: normal;
    font-size: 1.2rem;
}
.commentForm {
    display: flex;
    flex-direction: column;
}
.commentTextArea {
    margin: 5px auto 5px;
    padding: 5px;
    width: 93%;
    font-size: 1rem;
    height: 130px;
    border-color: #d3d3d3;
    border-radius: 5px;
}

.sendCommentButton {
    margin: 5px auto;
    border: 1px solid #01a2d8;
    border-radius: 7px;
    background-color: #e1fafa;
    transition: transform 0.2s;
    font-size: 1rem;
    padding: 5px 10px;
}

.sendCommentButton:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.sendCommentButton_disabled {
    transform: none !important;
    transition: none !important;
    cursor: auto;
    background-color: #dcd9d9;
}


@media screen and (max-width: 2150px) {
    .logo {
        width: 120px;
        height: 66px;
        margin-left: 30px;
    }

    .onDutyPortal {
        min-width: 160px;
        min-height: 34px;
        font-size: 1rem;
    }

    .searchForm {
        height: 33px;
    }

    .searchField {
        height: 33px;
        width: 280px;
        font-size: 1rem;
        padding: 0 6px;

    }
    .searchButton {
        height: 33px;
        width: 33px;
        border: none;
    }
    .resetSearchButton {
        height: 20px;
        width: 20px;
    }

    .alertsCount {
        margin: auto auto 0 33px;
        font-size: 1.5rem;
    }

    .alertCountItems {
        margin: 0 7px 0 0;
        padding: 3px 4px;
    }

    .alertsCountTitle {
        margin: 0 0 0 14px;
    }

    .avatar {
        width: 66px;
        height: 66px;
        margin: 10px 30px 0 auto;
        border: 2px solid slategrey;
    }

    .navbar {
        margin: auto auto 20px auto;
        width: 35%;
    }

    .headerButton {
        font-size: 1.4rem;
        min-width: 90px;
        min-height: 33px;
        padding: 8px 10px;
    }

    .funcButton {
        width: 34px;
        height: 34px;
    }

}

@media screen and (max-width: 1510px) {
    .logo {
        width: 100px;
        height: 44px;
        margin-left: 20px;
    }
    .onDutyPortal {
        min-width: 108px;
        min-height: 12px;
        font-size: 0.66rem;
    }

    .searchForm {
        height: 22px;
    }

    .searchField {
        height: 22px;
        width: 186px;
        font-size: 0.8rem;
        padding: 0 4px;

    }
    .searchButton {
        height: 22px;
        width: 22px;
        border: none;
    }
    .resetSearchButton {
        height: 14px;
        width: 14px;
    }

    .alertsCount {
        margin: auto auto 0 33px;
        font-size: 1rem;
    }

    .alertCountItems {
        margin: 0 5px 0 0;
        padding: 2px 3px;
    }

    .alertsCountTitle {
        margin: 0 0 0 10px;
    }

    .avatar {
        width: 44px;
        height: 44px;
        margin: 10px 20px 0 auto;
        border: 1px solid slategrey;
    }

    .navbar {
        margin: auto auto 14px auto;
        width: 30%;
    }

    .headerButton {
        font-size: 0.5rem;
        min-width: 60px;
        min-height: 22px;
        padding: 5px 6px;
    }
    .funcButton {
        width: 22px;
        height: 22px;
    }

    .alertsNumber {
        font-size: 1rem;
    }

    .alertsCountTitle {
        font-size: 1rem;
        margin-left: 7px;;
    }

    .inspectButton {
        display: none;
    }
}