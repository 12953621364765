.menu {
    height: 100%;
    width: 300px;
    background-color: #656B7A;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: width 0.2s;
}

.menu_closed {
   width: 0;
}


.menuHeader {
    text-align: center;
    font-size: 3rem;
    color: white;
    border-bottom: solid 2px white;
    padding-bottom: 10px;
    margin: 30px 0 20px 30px;
    width: 100%;
}

.menuList {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuItem {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
    margin: 20px auto auto 30px;
}

.menuButton {
    width: 100%;
    padding: 0 0 0 10px;
    text-align: start;
    margin-right: 30px;
    font-size: 1.7rem;
    text-decoration: none;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.menuButton:hover {
    background-color: #a9a8a81a;
    color: black;
}

.menuButtonActive {
    color: black;
    background-color: white;
}

@media screen and (max-width: 2150px) {
    .menu {
        width: 200px;
    }
    .menu_closed {
        width: 0;
    }
    .menuHeader {
        font-size: 2rem;
        padding-bottom: 6px;
        margin: 20px 0 15px 20px;
    }
    .menuItem {
        margin: 15px auto auto 20px;
    }
    .menuButton {
        margin-right: 20px;
        font-size: 1.13rem;
    }
}

@media screen and (max-width: 1510px) {
    .menu {
        width: 140px;
    }
    .menu_closed {
        width: 0;
    }
    .menuHeader {
        font-size: 1.5rem;
        padding-bottom: 4px;
        margin: 15px 0 10px 15px;
    }
    .menuItem {
        margin: 10px auto auto 15px;
    }
    .menuButton {
        margin-right: 15px;
        font-size: 0.75rem;
    }
}