.errorMessageHeader {
    display: flex;
    height: 15%;
    margin: 10px 5px;
    padding: 0 152px 15px 0;
    border-bottom: grey 1px solid;
}

.errorMessageLogo {
    width: 60px;
    height: 50px;
    background-image: url("../../../public/images/ivinco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto 0 auto 30px;
}

.errorMessageIcon {
    display: flex;
    width: 45px;
    height: 45px;
    background-image: url("../../../public/images/sad-face-2691.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
}

.errorMessageMessage {
    text-align: center;
    margin: 20px auto 40px;
    font-size: 1rem;
}