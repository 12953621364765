.wrapper {
    max-width: 1000px;
    overflow-y: auto;
}
.alertHeader {
    display: flex;
    width: calc(95% - 60px);
    margin: 15px 15px 0;
    align-items: center;
    justify-content: space-between;
}

.headerBlock {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 15px;
}

.severitySpan {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 10px;

}

.alertTitle {
    display: flex;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.respUserIcon {
    width: 28px;
    height: 28px;
    margin: 10px 15px 10px 0;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
}

.headerInfoBox {
    display: flex;
    margin: 10px 15px 10px 0;
    padding: 5px 8px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eee;
}

.alertDetailsBody {
    display: flex;
    margin: 0 5px 20px 5px;
    flex-direction: column;
    overflow-x: scroll;

}

.alertCommonInfo {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.alertInfoItem {
    display: flex;
    align-items: baseline;
}

.alertInfoKey {
    display: flex;
    font-size: 0.9rem;
    margin: 5px 10px;
    font-weight: bold;
}

.alertInfoValue {
    display: flex;
    font-size: 0.9rem;
    margin: 5px 10px;
    max-width: 70%;
}

.alertCommentButton {
    height: 25px;
    width: 25px;
    margin: auto 0;
    background-image: url("../../../public/images/pencil.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.2s;
    border: none;
    background-color: transparent;
}

.alertCommentButton:hover {
    transform: scale(1.2);
}

.alertInfoLink {
    display: flex;
    font-size: 0.9rem;
    margin-left: 15px;
    text-decoration: none;
    color: cornflowerblue;
    transition: transform 0.2s;
}

.alertInfoLink:hover {
    transform: scale(1.1);
}

.commentBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 24px;
    width: 90%;
    opacity: 1;
    transition: height 0.2s, opacity 0.3s;
}

.disabledBlock {
    height: 0;
    opacity: 0;
    transition: height 0.2s, opacity 0.3s;
}

.alertCommentInput {
    width: 63%;
    margin: 10px auto;
    height: 40px;
    overflow: auto;
    resize: none;
    font-size: 0.9rem;
}

.alertCommentSend {
    display: flex;
    margin: 10px auto;
    width: 25%;
    justify-content: center;
    font-size: 0.9rem;
    padding: 10px;
    background-color: #e1fafa;
    border: 3px #e1fafa solid;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s;
}

.alertCommentSend:hover {
    transform: scale(1.1);
}

.alertCommentSendDisabled {
    display: flex;
    margin: 10px auto;
    width: 25%;
    justify-content: center;
    font-size: 0.9rem;
    padding: 10px;
    background-color: #989898;
    border: 3px #989898 solid;
    border-radius: 20px;
}

.alertDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 50%;
    justify-content: center;
    margin-bottom: 10px;
}

.alertDetails h4 {
    display: flex;
    font-size: 1rem;
    margin: 10px auto;
    font-weight: bold;
}

.alertDetails code {
    max-height: 300px;
    display: block;
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 8px;
    margin: auto 10px;
    border-radius: 4px;
    white-space: pre-line;
    word-wrap: break-word;
    overflow-y: auto;
}

.historyBlock {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0 10px;
}

.historyTitle {
    text-align: center;
    font-size: 1rem;
    margin: 0;
}

.historyInputBlock {
    display: flex;
    margin: 5px auto 10px;
}

.historyInputElement {
    margin-right: 10px;
}

.historySearchButton {
    display: flex;
    width: 80px;
    border: 1px #656B7A solid;
    border-radius: 10%;
    background-color: transparent;
    background-image: url("../../../public/images/search.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.2s;
}

.historySearchButton:hover {
    cursor: pointer;
    transform: scale(0.9);
}
