.groupSection {
    display: flex;
    flex-direction: column;
    height:100%;
    width: 100%;
}

.groupBody {
    margin-bottom: 3px;
}

.groupBodyInspected {
    margin-bottom: 0;
    border: #8a8a8a solid 1px;
}

.groupBodyOpened {
    margin: auto;
    border-bottom: #8a8a8a solid 1px;
}

.alertsNumber {
    font-size: 1.2rem;
    font-weight: bold;
}

.alertsNumber_small {
    font-size: 0.7rem;
    font-weight: bold;
}

.alertsName {
    font-weight: bold;
}

.alertsGroupMessage {
    display: inline-block;
    height: 100%;
    width: 52%;
    align-content: center;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    border-left: slategrey solid 3px;
    border-right: slategrey solid 3px;
    word-break: break-word; /* Или попробуйте это свойство */
}

.alertsGroupMessage_small {
    display: inline-block;
    height: 100%;
    width: 52%;
    align-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    border-left: slategrey solid 1px;
    border-right: slategrey solid 1px;
    font-size: 0.6rem;
}


.alertBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.alertBlockHidden {
    display: none;
}

.tempHiddenButton {
    visibility: hidden;
}


@media screen and (max-width: 2150px) {
    .alertsNumber {
        font-size: 1rem;
    }
    .alertBlock {
        margin-bottom: 10px;
    }

    .alertBlockHidden {
        display: none;
    }
    .alertsGroupMessage {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 1510px) {
    .alertsNumber {
        font-size: 1rem;
    }
    .alertBlock {
        margin-bottom: 7px;
    }

    .alertBlockHidden {
        display: none;
    }
}

