.menuSpace {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: #e1fafa;
    margin: 0 0 10px 0;
    padding: 0;
    overflow: visible;
    opacity: 1;
    transition: height 0.2s ease-in-out, opacity 0.3s ease-in-out;
}

.menuClosed {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.menuItem {
    display: flex;
    flex-direction: column;
    margin: auto 40px 0;
    position: relative;
    min-width: 251px;
}


.filterMenu {
    display: flex;
    list-style: none;
    height: 60px;
    padding: 0;
    margin: auto;
    justify-content: space-between;
}
.settingsTumbler {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 40px 100px 0 auto;
}

.settingsSaver {
    opacity: 0;
    width: 0;
    height: 0;
}

.settingsSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.settingsSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.settingsSaver:checked + .settingsSlider {
    background-color: #2196F3;
}

.settingsSaver:checked + .settingsSlider:before {
    transform: translateX(26px);
}

@media screen and (max-width: 2150px) {
    .menuSpace {
        max-height: 66px;
    }
    .filterMenu {
        height: 45px;
    }
    .menuItem {
        min-width: 167px;
        margin: auto 27px 0;
    }
}

@media screen and (max-width: 1510px) {
    .menuSpace {
        max-height: 44px;
    }
    .filterMenu {
        height: 30px;
    }

    .menuItem {
        min-width: 111px;
        margin: auto 18px 0;
    }

    .settingsTumbler {
        width: 40px;
        height: 22px;
        margin: 20px 100px 0 auto;
    }

    .settingsSlider {
        border-radius: 22px;
    }

    .settingsSlider:before {
        height: 19px;
        width: 19px;
        left: 2px;
        bottom: 2px;
    }

    .settingsSaver:checked + .settingsSlider:before {
        transform: translateX(19px);
    }
}